.PropertyPicker {
	background-color: $white;
	padding: 40px 15px;
	position: relative;
	text-align: center;
	z-index: 1;

	@include viewport(medium) {
		max-width: 690px;
		padding: 40px 30px 30px;
		right: 50%;
		bottom: 0;
		transform: translateX(50%);
		width: 100%;

		// Use to align correctly the component in IE11 (remove horizontal scroll)
		@include rtl {
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	@include viewport(large) {
		position: absolute;
		max-width: 770px;
		padding: 40px 40px 30px;
	}

	@include viewport(xlarge) {
		max-width: 1270px;
		padding: 80px 80px 65px;
	}

	&-Title {
		margin-top: 0;
		margin-bottom: 40px;

		@include viewport(medium) {
			@include typography(h2);
			margin-bottom: 35px;
		}
	}

	&-Form {
		margin-bottom: 14px;

		@include viewport(medium) {
			display: flex;
		}

		@include viewport(xlarge) {
			margin-bottom: 23px;
		}
	}

	&-DropdownContainer {
		margin-bottom: 20px;
		position: relative;

		@include viewport(medium) {
			margin-bottom: 0;
			flex-grow: 1;
		}

		&::after {
			@include icon('icon-caret_down');
			position: absolute;
			font-size: 8px;
			top: 9px;
			right: 20px;
			pointer-events: none;

			@include viewport(xlarge) {
				top: 18px;
				right: 40px;
			}
		}

		@include rtl {
			&::after {
				left: 20px;
				right: auto;

				@include viewport(xlarge) {
					left: 40px;
					right: auto;
				}
			}
		}
	}

	&-Dropdown {
		background: $gray4;
		border-bottom: 0;
		padding: 0 20px;
		height: 40px;
		line-height: 45px;

		@include viewport(xlarge) {
			height: 60px;
			line-height: 65px;
			padding: 0 40px;
		}
	}

	&-CTA {
		// Fixes inline-flex issue of CTA text alignment for Safari iOS 6
		display: -webkit-inline-box;
		width: 100%;

		&Container {
			// Fixes disappearing border in Edge
			z-index: 1;
		}

		@include viewport(medium) {
			width: auto;
			flex-shrink: 0;
			flex-grow: 0;
			min-width: 160px;
		}

		@include viewport(xlarge) {
			font-size: 12px;
			height: 60px;
			min-width: 240px;
			padding: 0 60px;
		}
	}

	&-ArrowCTA.CTA {
		font-size: 10px;

		&::after {
			float: none;
		}
	}
}
